import React from "react";
import { Link } from "gatsby";
import logo from "../img/WesData-logo.png";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { navigate } from "@reach/router";
library.add( faUser, faSignInAlt, faSignOutAlt )

let sessionstorage = require('sessionstorage');

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  logout = () =>{
    this.wesdata=null;
    sessionstorage.setItem('wesdata', JSON.stringify(this.wesdata));
    navigate("/login");
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  toggleUserMenu = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        umActive: !this.state.umActive
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.umActive
          ? this.setState({
              umActiveClass: "is-active"
            })
          : this.setState({
              umActiveClass: ""
            });
      }
    );
  };

  render() {
    this.wesdata=JSON.parse(sessionstorage.getItem('wesdata'));
    if(this.wesdata){
      this.navAdd=
        
        (
        
        <div className={`navbar-item has-dropdown ${this.state.umActiveClass}`} onClick={() => this.toggleUserMenu()}>
          <a className="navbar-link">
            <FontAwesomeIcon icon="user" color="#666"/>
          </a>
          <div className="navbar-dropdown">
            <a className="navbar-item" onClick={() => navigate("/dashboard/profile")}>
            Profile &nbsp;<FontAwesomeIcon icon="user" color="#666"/>
            </a>
            <a className="navbar-item" onClick={() => this.logout()}>
              Sign Out &nbsp;<FontAwesomeIcon icon="sign-out-alt" color="#666"/>
            </a>
          </div>
        </div>);
    }else{
      this.navAdd=
        
        (
        
        <div className={`navbar-item`}>
          <a href="/login" className="navbar-item">
            Sign In &nbsp;<FontAwesomeIcon icon="sign-in-alt" color="#666"/>
          </a>
        </div>);
    }
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="WesData" style={{ width: "200px", marginRight: "80px" }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/services">
                Services
              </Link>
              <Link className="navbar-item" to="/news">
                News
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
              <Link className="navbar-item" to="/careers">
                Careers
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              {this.navAdd}
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
