import React from 'react'
import Layout from '../../components/Layout'
import {Link} from 'gatsby'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>Thanks for contacting us. Someone will be in touch with you soon to discuss your needs.</p>
          <p><Link to="/">WesData Home</Link></p>
        </div>
      </div>
    </section>
  </Layout>
)
