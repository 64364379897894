import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import './all.sass'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faRss, faWrench, faTachometerAlt, faTasks, faCog, faCogs, faCloudDownloadAlt, faProjectDiagram, faShoppingCart, faHandshake, faUsers, faChartBar, faChartLine, faCheckCircle, faCheckSquare, faFilter, faBalanceScale, faPaperPlane, faPhoneVolume, faBuilding } from '@fortawesome/free-solid-svg-icons'
library.add(faRss,faWrench,faTachometerAlt,faTasks, faCog, faCogs, faCloudDownloadAlt, faProjectDiagram, faShoppingCart, faHandshake, faUsers, faChartBar, faChartLine, faCheckCircle, faFilter, faCheckSquare, faBalanceScale, faPaperPlane, faPhoneVolume, faBuilding)

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href="/img/safari-pinned-tab.svg"
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content="/img/og-image.jpg" />
        </Helmet>
        
        <Navbar />
        <div>{children}</div>
        <Footer />
      </div>
    )}
  />
)

export default TemplateWrapper
